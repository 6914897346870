import axios from 'axios';
import Vue from 'vue';
import { i18n } from '../plugins/i18n';

export const API_ORIGIN = localStorage.getItem('VUE_APP_CORE_SERVER_ORIGIN');
export const REAL_ESTATE_API_ORIGIN = localStorage.getItem(
  'VUE_APP_REAL_ESTATE_API_GATEWAY_URL',
);
export const MERCHANT_ID = localStorage.getItem('MERCHANT_ID');
export const BASE_API_URL = API_ORIGIN + '/api/v1';
export const BASE_CHECKOUT_API = API_ORIGIN + '/checkout/v1';
export const BASE_INVOICE_API = API_ORIGIN + '/invoice/v1';
export const BASE_REAL_ESTATE_API = REAL_ESTATE_API_ORIGIN + '/api/v1';

// Authorization header is inserted automatically to each request (if token is present)
export const authInterceptor = [
  (config) => {
    if (Vue.prototype.$token()) {
      config.headers['Accept-language'] = i18n.locale;
      config.headers.Authorization = `Bearer ${Vue.prototype.$token()}`;
    }

    delete config.headers.noAuthRequired;

    return config;
  },
  (error) => Promise.reject(error),
];

export const merchantHeaderInterceptor = [
  (config) => {
    config.headers['X-Merchant'] = MERCHANT_ID;

    return config;
  },
  (error) => Promise.reject(error),
];

export const timezoneInterceptor = [
  config => {
    if (localStorage.getItem("userTimeZone") && localStorage.getItem("userTimeZone") !== 'null') {
      config.headers['X-Timezone'] = localStorage.getItem("userTimeZone");
    }
    return config;
  },
  error => Promise.reject(error),
];

export const Service = (url) =>
  axios.create({
    baseURL: BASE_API_URL + url,
  });

export const AuthorizedCheckoutService = (url) => {
  const checkoutService = axios.create({
    baseURL: BASE_CHECKOUT_API + url,
  });
  checkoutService.interceptors.request.use(...authInterceptor);
  checkoutService.interceptors.request.use(...timezoneInterceptor);
  return checkoutService;
};

export const AuthorizedInvoiceService = (url) => {
  const invoiceService = axios.create({
    baseURL: BASE_INVOICE_API + url,
  });
  invoiceService.interceptors.request.use(...authInterceptor);
  invoiceService.interceptors.request.use(...timezoneInterceptor);

  return invoiceService;
};

export const AuthorizedService = (url) => {
  const authorizedService = Service(url);
  authorizedService.interceptors.request.use(...authInterceptor);
  authorizedService.interceptors.request.use(...timezoneInterceptor);
  return authorizedService;
};

export const AuthorizedRealEstateService = (url) => {
  const authEstateService = axios.create({
    baseURL: BASE_REAL_ESTATE_API + url,
  });
  authEstateService.interceptors.request.use(...authInterceptor);
  authEstateService.interceptors.request.use(...merchantHeaderInterceptor);
  authEstateService.interceptors.request.use(...timezoneInterceptor);

  return authEstateService;
};
